import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

const projects = [
  {
    title: "Discord Bot: Sanz",
    description:
      "An interactive Discord chatbot that can entertain you with a few commands.",
    getImageSrc: () => require("../images/Sanz.png"),
    link: "https://sanz.bufferization.com",
  },
  {
    title: "AzureS (Roblox Colorbot External)",
    description:
      "A Roblox colorbot external aimbot (PAID)",
    getImageSrc: () => require("../images/azure.png"),
    link: "https://discord.gg/9VJuNutke6"
  },
  {
    title: "complex-rs",
    description: "A Rust library for complex number operations, with support for basic arithmetic, trigonometric, and hyperbolic functions.",
    getImageSrc: () => require("../images/rs.png"),
    link: "https://github.com/24rr/complex-rs"
  },
  {
    title: "brasp-rs",
    description: "Rust library designed to handle configuration parsing for command-line applications.",
    getImageSrc: () => require("../images/rs.png"),
    link: "https://24rr.github.io/brasp-rs/#/"
  },
  {
    title: "rusty-openai",
    description: "An unofficial OpenAI wrapper that supports image inputs",
    getImageSrc: () => require("../images/open.png"),
    link: "https://github.com/24rr/rusty-openai"
  },
  {
    title: "rusty-anthropic",
    description: "An unofficial Rust SDK for interacting with the Anthropic API",
    getImageSrc: () => require("../images/anthropic.png"),
    link: "https://github.com/24rr/rusty-anthropic"
  },
  {
    title: "rusty-termcolor",
    description: "A light-weight terminal coloring library for Rust",
    getImageSrc: () => require("../images/rs.png"),
    link: "https://github.com/24rr/rusty-termcolor"
  },
];

const ProjectsSection = () => {
  return (
    <FullScreenSection
      backgroundColor="black"
      backgroundImage="https://raw.githubusercontent.com/24rr/tmpignore/main/bg.png"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
      fontFamily="Anta"
      id="projects-section"
      paddingTop="80px"
      minHeight={"100vh"}
    >
      <Heading as="h1" fontFamily="anta" fontWeight={400}>
      My Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, minmax(0, 1fr))" }}
        gridGap={8}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            link={project.link}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
