import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import LandingSection from "./components/LandingSection";
import ProjectsSection from "./components/ProjectsSection";
import SkillsSection from "./components/SkillsSection";
import Footer from "./components/Footer";
import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const BackgroundAudio = new Audio(require("./music/warm.mp3"));
    BackgroundAudio.loop = true;
    BackgroundAudio.volume = 0.3;

    const handleUserInteraction = () => {
      BackgroundAudio.play().catch(e => console.error(e));
      document.removeEventListener('click', handleUserInteraction);
    };

    document.addEventListener('click', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      BackgroundAudio.pause();
      BackgroundAudio.currentTime = 0;
    };
  }, []);

  return (
    <ChakraProvider>
      <main>
        <Header />
        <LandingSection />
        <ProjectsSection />
        <SkillsSection />
        <Footer />
      </main>
      <Analytics />
    </ChakraProvider>
  );
}

export default App;