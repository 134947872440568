import React from "react";
import {
  VStack,
  Box,
  HStack,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import Card from "./Card";
import "@fontsource/karla";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPython,
  faNodeJs,
  faCuttlefish,
  faRust,
} from "@fortawesome/free-brands-svg-icons";

const SkillsSection = () => {
  const CircularProgressSize = "calc(30px + 5.6vw)";
  const CircularProgressThickness = "6px";

  const contributions = [
    {
      title: "Adonis Roblox",
      description: "Made the Audit Log system + commands.",
      getImageSrc: () => require("../images/Adonis.png"),
      link: "https://github.com/Epix-Incorporated/Adonis",
    },
    {
      title: "Ghost Language (Custom Language)",
      description: "Contributed AST and more syntaxes and functions",
      getImageSrc: () => require("../images/git.jpg"),
    },
    {
      title: "Wave Executor",
      description: "Contributed to the UI syntax",
      getImageSrc: () => require("../images/wave.png"),
    }
  ];

  return (
    <FullScreenSection
      isDarkBackground
      backgroundColor="#090A0A"
      p={"8%"}
      id="skills-section"
      minHeight={"50vh"}
    >
      <VStack gap={"50px"}>
        <VStack gap={10}>
          <Heading as="h1" fontFamily={"Anta"} fontWeight={400}>
            My Skills
          </Heading>
          <HStack>
            <CircularProgress
              value={75}
              color="yellow.400"
              thickness={CircularProgressThickness}
              size={CircularProgressSize}
            >
              <CircularProgressLabel>
                <FontAwesomeIcon icon={faPython} color="yellow" size="2x" />
              </CircularProgressLabel>
            </CircularProgress>
            <CircularProgress
              value={94}
              color="yellow.400"
              thickness={CircularProgressThickness}
              size={CircularProgressSize}
            >
              <CircularProgressLabel>
                <FontAwesomeIcon icon={faCuttlefish} color="blue" size="2x" />
              </CircularProgressLabel>
            </CircularProgress>
            <CircularProgress
              value={82}
              color="blue.400"
              thickness={CircularProgressThickness}
              size={CircularProgressSize}
            >
              <CircularProgressLabel>
                <FontAwesomeIcon icon={faRust} color="orange" size="2x" />
              </CircularProgressLabel>
            </CircularProgress>
            <CircularProgress
              value={65}
              color="orange.400"
              thickness={CircularProgressThickness}
              size={CircularProgressSize}
            >
              <CircularProgressLabel>
                <FontAwesomeIcon icon={faNodeJs} color="green" size="2x" />
              </CircularProgressLabel>
            </CircularProgress>
          </HStack>
        </VStack>

        <Accordion
          allowToggle
          w={"90vw"}
          backgroundColor={"black"}
          borderColor={"#090A0A"}
          borderRadius={"20px"}
          boxShadow={
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
          }
        >
          <AccordionItem>
            <h2>
              <AccordionButton p={5}>
                <Box as="span" flex="1" textAlign="center">
                  <Heading
                    as="h3"
                    fontFamily={"Anta"}
                    fontWeight={200}
                    fontSize={"20px"}
                  >
                    Contributions
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel p={8}>
              <Box
                display="grid"
                gridTemplateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, minmax(0, 1fr))",
                }}
                gridGap={8}
                mb={4}
              >
                {contributions.map((contribution) => (
                  <Card
                    key={contribution.title}
                    title={contribution.title}
                    description={contribution.description}
                    imageSrc={contribution.getImageSrc()}
                    link={contribution.link}
                  />
                ))}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </FullScreenSection>
  );
};

export default SkillsSection;