import React, { useEffect, useState } from "react";
import { Avatar, Heading } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

const Greeting = () => {
  return (
    <span>
      Yo g! I'm Bernward (<span className="hoverBorderAnimation">Bufferization</span>)
    </span>
  );
};

const Bio1 = () => {
  return (
    <span>
      I do script in Roblox Luau, Python, JavaScript, and.. More!
    </span>
  );
};

const LandingSection = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <FullScreenSection
      justifyContent="center"
      alignContent="center"
      isDarkBackground
      backgroundColor="black"
      backgroundImage="url('https://raw.githubusercontent.com/24rr/tmpignore/main/dqws.png')"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      minHeight={"100vh"}
    >
      <Avatar
        id="avatar"
        name="Buffer"
        size="xl"
        src="https://raw.githubusercontent.com/24rr/tmpignore/main/sanz_gun_with_skull_mixed_face_symmetrical_flat_icon_design_f1895bf6-4cce-4db2-bdb8-f7429d4d3443.png"
        style={{ opacity: showContent ? 1 : 0, transition: "opacity 1s" }}
      />
      <Heading
        as="h1"
        size="lg"
        textAlign="center"
        style={{ opacity: showContent ? 1 : 0, transition: "opacity 0.5s" }}
        fontFamily="anta"
        fontWeight="600"
      >
        <Greeting />
      </Heading>
      <Heading
        as="h2"
        size="md"
        textAlign="center"
        style={{ opacity: showContent ? 1 : 0, transition: "opacity 0.5s" }}
        fontFamily="anta"
        fontWeight="200"
      >
        <Bio1 />
      </Heading>
    </FullScreenSection>
  );
};

export default LandingSection;