import * as React from "react";
import { VStack } from "@chakra-ui/react";

const FullScreenSection = ({ children, isDarkBackground, ...boxProps }) => {
  return (
    <VStack
      backgroundColor={boxProps.backgroundColor}
      color={isDarkBackground ? "white" : "black"}
      backgroundImage={boxProps.backgroundImage}
      backgroundRepeat={boxProps.backgroundRepeat}
      backgroundSize={boxProps.backgroundSize}
      fontFamily={boxProps.fontFamily}
      minHeight="100vh"
    >
      <VStack maxWidth="1280px" {...boxProps}>
        {children}
      </VStack>
    </VStack>
  );
};

export default FullScreenSection;